* {
  font-family: "Open sans";
  font-family: "gilmer",sans-serif;
  box-sizing: border-box;
  transition: all 0.2s linear;
  padding: 0px;
  margin: 0px;
  scroll-behavior: smooth;
}

body {
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
}